// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()

// ***********Bootstrap JS, CSS BootstrapDatepicker CSS, JS and Locale************
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker.min";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min";
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.ja.min";

// Custom CSS
import "@assets/stylesheets/application";

// CSS from Customer
import "@template/scss/miyasys/style";

//navigation
import "@assets/javascripts/nav_bar";

// Clear all LocalStore Key
window.localStorage.clear();

// ***********Lodash************
import Vue from "vue";
import _ from "lodash";
Object.defineProperty(Vue.prototype, "_", { value: _ });

// ***********Moment Timezone'************
import "moment/locale/ja";
import moment from "moment-timezone";
moment.tz.setDefault("Asia/Tokyo");
moment.locale("ja");
Object.defineProperty(Vue.prototype, "$moment", { value: moment });

import bootbox from "bootbox";
Object.defineProperty(Vue.prototype, "$bootbox", { value: bootbox });

// ***********Jquery************
import $ from "jquery";
global.$ = $;
global.jQuery = $;

// ***********Event Bus************
import "@src/common/eventBus";

// ***********Global Components************
import "@src/common/globalComponents";

// ************Global Mixins************
import appMixins from "@src/mixins/appMixins";
Vue.mixin(appMixins);

// ************ConTextMenu Mixins************
import contextMenuMixins from "@src/mixins/contextMenuMixins";
Vue.mixin(contextMenuMixins);

import localStorageMixin from "@src/mixins/localStorageMixin";
Vue.mixin(localStorageMixin);

// ************Vue Tooltip************
import VTooltip from "v-tooltip";
Vue.use(VTooltip);

// ************Handle jQuery ui datepicker conflict with bootstrap datepicker************
const datepicker = $.fn.datepicker.noConflict();
$.fn.bootstrapDatePicker = datepicker;
require("jquery-ui");

// ************Vue Columns Resizable************
import VueColumnsResizable from "vue-columns-resizable";
Vue.use(VueColumnsResizable);

// ************Vue Notifications************
import Notifications from "vue-notification";
Vue.use(Notifications);

// ************Vue Cookies************
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

// ************Vue Calendar************
import VCalendar from "v-calendar";
Vue.use(VCalendar);

require("@rails/ujs").start();
require("channels");
require.context("@assets/images", true);

import Settings from "@assets/javascripts/settings";
document.addEventListener("DOMContentLoaded", () => {
  $("#company-recalculation-projects").on("click", () => {
    bootbox.dialog({
      title: Settings.MODAL_MAINTAINANCE_COMPANY.TITLE,
      message: Settings.MODAL_MAINTAINANCE_COMPANY.MESSAGE,
      onShown: function (e) {
        $(e.target).find(".btn-default:first").focus();
      },
      buttons: {
        btnSave: {
          label: Settings.MODAL_MAINTAINANCE_COMPANY.SAVE_LABEL,
          className: Settings.MODAL_MAINTAINANCE_COMPANY.SAVE_CLASS,
          callback: async (result) => {
            $("form[data-autosubmit]").submit();
          },
        },
        btnClose: {
          label: Settings.MODAL_MAINTAINANCE_COMPANY.CLOSE_LABEL,
          className: Settings.MODAL_MAINTAINANCE_COMPANY.CLOSE_CLASS,
        },
      },
    });
  });

  $(".deny-company-master-data-alert").on("click", () => {
    bootbox.alert({
      title: Settings.ALERT_TEXT.ACCOUNT_CAPABILITY_COMPANY_MASTER_DATA_TITLE,
      message: Settings.ALERT_TEXT.ACCOUNT_CAPABILITY_COMPANY_MASTER_DATA_MSG,
    });
  });
  $(".deny-option-inquiry-data-alert").on("click", () => {
    bootbox.alert({
      title: Settings.ALERT_TEXT.ACCOUNT_CAPABILITY_OPTION_INQUIRY_TITLE,
      message: Settings.ALERT_TEXT.ACCOUNT_CAPABILITY_OPTION_INQUIRY_MSG,
    });
  });

  $(".btn_cancel_event").on("click", (event) => {
    bootbox.dialog({
      message: Settings.CANCEL_CONFIRM.MESSAGE,
      buttons: {
        btnSave: {
          label: Settings.CANCEL_CONFIRM.SAVE_LABEL,
          className: Settings.CANCEL_CONFIRM.SAVE_CLASS,
          callback: function (result) {
            if (result) {
              $("form").submit();
            }
          },
        },
        btnClose: {
          label: Settings.CANCEL_CONFIRM.CLOSE_LABEL,
          className: Settings.CANCEL_CONFIRM.CLOSE_CLASS,
        },
      },
    });
    return event.preventDefault();
  });

  $(".change_email").on("click", (event) => {
    bootbox.dialog({
      message: Settings.CHANGE_EMAIL.MESSAGE,
      buttons: {
        btnSave: {
          label: Settings.CANCEL_CONFIRM.SAVE_LABEL,
          className: Settings.CANCEL_CONFIRM.SAVE_CLASS,
          callback: function (result) {
            if (result) {
              $("form").submit();
            }
          },
        },
        btnClose: {
          label: Settings.CANCEL_CONFIRM.CLOSE_LABEL,
          className: Settings.CANCEL_CONFIRM.CLOSE_CLASS,
        },
      },
    });
    return event.preventDefault();
  });

  $(".change_password").on("click", (event) => {
    bootbox.dialog({
      message: Settings.CHANGE_PASSWORD.MESSAGE,
      buttons: {
        btnSave: {
          label: Settings.CANCEL_CONFIRM.SAVE_LABEL,
          className: Settings.CANCEL_CONFIRM.SAVE_CLASS,
          callback: function (result) {
            if (result) {
              $("form").submit();
            }
          },
        },
        btnClose: {
          label: Settings.CANCEL_CONFIRM.CLOSE_LABEL,
          className: Settings.CANCEL_CONFIRM.CLOSE_CLASS,
        },
      },
    });
    return event.preventDefault();
  });

  $(".deny-change-usage-plan-alert").on("click", () => {
    bootbox.alert({
      message: "解約申請中のアカウントはご利用プランが変更できません。",
    });
  });
});
