import Settings from "@assets/javascripts/settings";
const projectUnitCostColumn = [
  {
    name: "",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.CHECKBOX,
    check_all: false,
  },
  {
    attribute: "unit_cost_type",
    name: "費目",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.SELECT,
  },
  {
    attribute: "name",
    name: "名称",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.INPUT,
    class: "text-left",
  },
  {
    attribute: "unit_cost_category",
    name: "分類",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.SELECT_TAGGABLE,
  },
  {
    name: "実行予算単価",
    is_show: true,
    is_number: true,
    minus: true,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    attribute: "project_unit",
    name: "単位",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.SELECT_TAGGABLE,
  },
  {
    attribute: "project_supplier",
    name: "会社名",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.SELECT_TAGGABLE,
  },
  {
    name: "積算単価",
    is_show: true,
    is_number: true,
    minus: true,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "見積単価",
    is_show: true,
    is_number: true,
    minus: true,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "その他単価1",
    is_show: true,
    is_number: true,
    minus: true,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "その他単価2",
    is_show: true,
    is_number: true,
    minus: true,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    attribute: "project_book_account",
    name: "科目",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.SELECT_TAGGABLE,
  },
  {
    name: "コード",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "通常",
    is_show: true,
    is_number: true,
    precision: 0,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "残業",
    is_show: true,
    is_number: true,
    precision: 0,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "深夜",
    is_show: true,
    is_number: true,
    precision: 0,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "休日",
    is_show: true,
    is_number: true,
    precision: 0,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "単価",
    is_show: true,
    is_number: true,
    precision: 0,
    code: "allowance1",
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "単価",
    is_show: true,
    is_number: true,
    precision: 0,
    code: "allowance2",
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "単価",
    is_show: true,
    is_number: true,
    precision: 0,
    code: "allowance3",
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "単価",
    is_show: true,
    is_number: true,
    precision: 0,
    code: "allowance4",
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "単価",
    is_show: true,
    is_number: true,
    precision: 0,
    code: "allowance5",
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "時間単価",
    is_show: true,
    is_number: true,
    precision: 0,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "作成日",
    is_show: true,
    is_number: false,
    width: "86px",
  },
  {
    name: "更新日",
    is_show: true,
    is_number: false,
    width: "86px",
  },
  {
    name: "経費率",
    is_show: true,
    is_number: false,
    width: "86px",
  },
  {
    name: "経費率数値",
    is_show: true,
    is_number: false,
    width: "86px",
  },
  {
    name: "積算",
    is_show: false,
    is_number: false,
    type: Settings.CELL_TYPE.ICON,
  },
  {
    name: "実行",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.ICON,
  },
  {
    name: "削除",
    is_show: true,
    type: Settings.CELL_TYPE.BUTTON,
    class:
      "btn btn-secondary border font-size-11 py-0 px-1 creatework_button text-nowrap mx-2",
    text: "削除",
  },
];

const columnsLaborSalary = [
  "通常",
  "残業",
  "深夜",
  "休日",
  "単価",
  "単価",
  "単価",
  "単価",
  "単価",
];

const columnsMachinery = ["時間単価"];

const columnsDefault = [
  "費目",
  "名称",
  "分類",
  "実行予算単価",
  "単位",
  "会社名",
  "積算単価",
  "見積単価",
  "その他単価1",
  "その他単価2",
  "科目",
  "コード",
  "作成日",
  "更新日",
  "経費率",
  "経費率数値",
  "実行",
  "削除",
  "",
];

const conditionType = {
  all: "全て",
  labor: "労務費",
  machinery: "車輌機械費",
  machinery_special: "機械費・運搬費",
};

const companyUnitCostColumn = [
  {
    name: "",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.CHECKBOX,
    check_all: false,
    width: "40px",
  },
  {
    attribute: "unit_cost_type",
    name: "費目",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.SELECT,
  },
  {
    name: "名称",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    attribute: "company_unit_cost_category",
    name: "分類",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.SELECT_TAGGABLE,
  },
  {
    name: "実行予算単価",
    is_show: true,
    is_number: true,
    minus: true,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    attribute: "company_unit",
    name: "単位",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.SELECT_TAGGABLE,
  },
  {
    attribute: "company_supplier",
    name: "会社名",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.SELECT_TAGGABLE,
  },
  {
    name: "積算単価",
    is_show: true,
    is_number: true,
    minus: true,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "見積単価",
    is_show: true,
    is_number: true,
    minus: true,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "その他単価1",
    is_show: true,
    is_number: true,
    minus: true,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "その他単価2",
    is_show: true,
    is_number: true,
    minus: true,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    attribute: "company_book_account",
    name: "科目",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.SELECT_TAGGABLE,
  },
  {
    name: "コード",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "通常",
    is_show: true,
    is_number: true,
    precision: 0,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "残業",
    is_show: true,
    is_number: true,
    precision: 0,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "深夜",
    is_show: true,
    is_number: true,
    precision: 0,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "休日",
    is_show: true,
    is_number: true,
    precision: 0,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "単価",
    is_show: true,
    is_number: true,
    precision: 0,
    code: "allowance1",
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "単価",
    is_show: true,
    is_number: true,
    precision: 0,
    code: "allowance2",
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "単価",
    is_show: true,
    is_number: true,
    precision: 0,
    code: "allowance3",
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "単価",
    is_show: true,
    is_number: true,
    precision: 0,
    code: "allowance4",
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "単価",
    is_show: true,
    is_number: true,
    precision: 0,
    code: "allowance5",
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "時間単価",
    is_show: true,
    is_number: true,
    precision: 0,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "作成日",
    is_show: true,
    is_number: false,
    width: "86px",
  },
  {
    name: "更新日",
    is_show: true,
    is_number: false,
    width: "86px",
  },
  // {
  //   name: "経費率",
  //   is_show: true,
  //   is_number: false,
  //   width: "86px",
  // },
  {
    name: "削除",
    is_show: true,
    type: Settings.CELL_TYPE.BUTTON,
    class:
      "btn btn-secondary border font-size-11 py-0 px-1 creatework_button text-nowrap mx-2",
    text: "削除",
    width: "60px",
  },
];

const userUnitCostCopyColumn = [
  {
    name: "",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.CHECKBOX,
    check_all: false,
  },
  {
    name: "費目",
    is_show: true,
    is_number: false,
  },
  {
    name: "名称",
    is_show: true,
    is_number: false,
  },
  {
    name: "分類",
    is_show: true,
    is_number: false,
  },
  {
    name: "実行予算単価",
    is_show: true,
    is_number: true,
  },
  {
    name: "単位",
    is_show: true,
    is_number: false,
  },
  {
    name: "会社名",
    is_show: true,
    is_number: false,
  },
  {
    name: "積算単価",
    is_show: true,
    is_number: true,
  },
  {
    name: "見積単価",
    is_show: true,
    is_number: true,
  },
  {
    name: "その他単価1",
    is_show: true,
    is_number: true,
  },
  {
    name: "その他単価2",
    is_show: true,
    is_number: true,
  },
  {
    name: "科目",
    is_show: true,
    is_number: false,
  },
  {
    name: "コード",
    is_show: true,
    is_number: false,
  },
];

const companyUnitCostCopyColumn = [
  {
    name: "",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.CHECKBOX,
    check_all: false,
  },
  {
    name: "費目",
    is_show: true,
    is_number: false,
  },
  {
    name: "名称",
    is_show: true,
    is_number: false,
  },
  {
    name: "分類",
    is_show: true,
    is_number: false,
  },
  {
    name: "実行予算単価",
    is_show: true,
    is_number: true,
  },
  {
    name: "単位",
    is_show: true,
    is_number: false,
  },
  {
    name: "会社名",
    is_show: true,
    is_number: false,
  },
  {
    name: "積算単価",
    is_show: true,
    is_number: true,
  },
  {
    name: "見積単価",
    is_show: true,
    is_number: true,
  },
  {
    name: "その他単価1",
    is_show: true,
    is_number: true,
  },
  {
    name: "その他単価2",
    is_show: true,
    is_number: true,
  },
  {
    name: "科目",
    is_show: true,
    is_number: false,
  },
  {
    name: "コード",
    is_show: true,
    is_number: false,
  },
];

const userUnitCostColumn = [
  {
    name: "",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.CHECKBOX,
    check_all: false,
    width: "40px",
  },
  {
    attribute: "unit_cost_type",
    name: "費目",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.SELECT,
  },
  {
    name: "名称",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    attribute: "user_unit_cost_category",
    name: "分類",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.SELECT_TAGGABLE,
  },
  {
    name: "実行予算単価",
    is_show: true,
    is_number: true,
    minus: true,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    attribute: "user_unit",
    name: "単位",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.SELECT_TAGGABLE,
  },
  {
    attribute: "user_supplier",
    name: "会社名",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.SELECT_TAGGABLE,
  },
  {
    name: "積算単価",
    is_show: true,
    is_number: true,
    minus: true,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "見積単価",
    is_show: true,
    is_number: true,
    minus: true,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "その他単価1",
    is_show: true,
    is_number: true,
    minus: true,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "その他単価2",
    is_show: true,
    is_number: true,
    minus: true,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    attribute: "user_book_account",
    name: "科目",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.SELECT_TAGGABLE,
  },
  {
    name: "コード",
    is_show: true,
    is_number: false,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "通常",
    is_show: true,
    is_number: true,
    precision: 0,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "残業",
    is_show: true,
    is_number: true,
    precision: 0,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "深夜",
    is_show: true,
    is_number: true,
    precision: 0,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "休日",
    is_show: true,
    is_number: true,
    precision: 0,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "単価",
    is_show: true,
    is_number: true,
    precision: 0,
    code: "allowance1",
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "単価",
    is_show: true,
    is_number: true,
    precision: 0,
    code: "allowance2",
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "単価",
    is_show: true,
    is_number: true,
    precision: 0,
    code: "allowance3",
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "単価",
    is_show: true,
    is_number: true,
    precision: 0,
    code: "allowance4",
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "単価",
    is_show: true,
    is_number: true,
    precision: 0,
    code: "allowance5",
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "時間単価",
    is_show: true,
    is_number: true,
    precision: 0,
    type: Settings.CELL_TYPE.INPUT,
  },
  {
    name: "作成日",
    is_show: true,
    is_number: false,
    width: "86px",
  },
  {
    name: "更新日",
    is_show: true,
    is_number: false,
    width: "86px",
  },
  // {
  //   name: "経費率",
  //   is_show: true,
  //   is_number: false,
  //   width: "86px",
  // },
  {
    name: "削除",
    is_show: true,
    type: Settings.CELL_TYPE.BUTTON,
    class:
      "btn btn-secondary border font-size-11 py-0 px-1 creatework_button text-nowrap mx-2",
    text: "削除",
    width: "60px",
  },
];

export {
  projectUnitCostColumn,
  columnsLaborSalary,
  columnsMachinery,
  columnsDefault,
  conditionType,
  companyUnitCostColumn,
  userUnitCostCopyColumn,
  userUnitCostColumn,
  companyUnitCostCopyColumn,
};
