export default {
  methods: {
    $openMenu(selected, event, selectedIndex = null) {
      event.preventDefault();
      this.$refs["context-menu"].open(event, {
        selected,
        selectedIndex,
      });
    },
    $onCtxOpen(locals) {
      this.selected = locals.selected;
      this.selectedIndex = locals.selectedIndex;
    },
    $resetCtxLocals() {
      this.selected = null;
      this.selectedIndex = null;
    },
  },
};
