import ls from "local-storage";

export default {
  methods: {
    $pushLocalStores(key, msg) {
      ls.set(key, msg);
    },
    $pullLocalStores(key) {
      return ls.get(key);
    },
    $removeLocalStores(key) {
      return ls.remove(key);
    },
    $attachLocalStoresListener(key, callback) {
      ls.on(key, callback);
    },
  },
};
