$(function () {
  let overFlg;
  $(".menu").click(function () {
    if ($(this).attr("class") === "selected") {
      // メニュー非表示
      $(this).removeClass("selected").next(".nav-child").slideUp("fast");
    } else {
      // 表示しているメニューを閉じる
      $(".menu").removeClass("selected");
      $(".nav-child").hide();

      // メニュー表示
      $(this).addClass("selected").next(".nav-child").slideDown("fast");
    }
  });

  // マウスカーソルがメニュー上/メニュー外
  $(".menu,.nav-child").hover(
    function () {
      overFlg = true;
    },
    function () {
      overFlg = false;
    }
  );

  // メニュー領域外をクリックしたらメニューを閉じる
  $("body").click(function () {
    if (overFlg === false) {
      $(".menu").removeClass("selected");
      $(".nav-child").slideUp("fast");
    }
  });
});
