import Vue from "vue";

Vue.component("AppSpinner", () => import("@src/components/AppSpinner"));
Vue.component("ContextMenu", () =>
  import("@src/components/ContextMenu/BaseContextMenu")
);
Vue.component("RootModal", () => import("@src/components/RootModal"));
Vue.component("BaseTable", () => import("@src/components/Table/BaseTable"));
Vue.component("BaseLine", () => import("@src/components/Table/BaseLine"));
Vue.component("BaseHeader", () => import("@src/components/Table/BaseHeader"));
Vue.component("InputCell", () => import("@src/components/Table/InputCell"));
Vue.component("RestrictionIputCell", () =>
  import("@src/components/Table/RestrictionIputCell")
);
Vue.component("CheckBoxCell", () =>
  import("@src/components/Table/CheckBoxCell")
);
Vue.component("RestrictionCheckBoxCell", () =>
  import("@src/components/Table/RestrictionCheckBoxCell")
);
Vue.component("SelectCell", () => import("@src/components/Table/SelectCell"));
Vue.component("RestrictionSelectCell", () =>
  import("@src/components/Table/RestrictionSelectCell")
);
Vue.component("SelectCellByRow", () =>
  import("@src/components/Table/SelectCellByRow")
);
Vue.component("RestrictionSelectCellByRow", () =>
  import("@src/components/Table/RestrictionSelectCellByRow")
);
Vue.component("ButtonCell", () => import("@src/components/Table/ButtonCell"));
Vue.component("RestrictionButtonCell", () =>
  import("@src/components/Table/RestrictionButtonCell")
);
Vue.component("IconCell", () => import("@src/components/Table/IconCell"));
Vue.component("DefaultCell", () => import("@src/components/Table/DefaultCell"));
Vue.component("CsvDownLoad", () => import("@src/components/CsvDownLoad"));
Vue.component("VueNumeric", () => import("@src/components/VueNumeric"));
