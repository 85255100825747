import Settings from "@assets/javascripts/settings";

const machineryCostNames = () => {
  let companySlug = window.location.pathname.split("/")[1];

  if (companySlug === Settings.COMPANY_SLUG.SLUG_SPECIAL) {
    return Settings.MACHINERY.LIST_SPECIAL;
  } else {
    return Settings.MACHINERY.LIST_NORMAL;
  }
};

export { machineryCostNames };
