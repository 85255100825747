// There is the global function, data... will use in all screens
import Settings from "@assets/javascripts/settings";
import axios from "axios";
import { BigNumber } from "bignumber.js";

import {
  columnsLaborSalary,
  columnsMachinery,
  columnsDefault,
  conditionType,
} from "@src/common/tableHelper";
import { machineryCostNames } from "@src/utilities/costNameUtility";

export default {
  data() {
    let displayPoint = document
      .getElementById("display_point")
      .getAttribute("display_point");
    let winURL = window.location.origin;
    let companySlug = window.location.pathname.split("/")[1];
    let popupWindow = (window.popupWindow = new Array());
    return {
      winURL: winURL,
      companySlug: companySlug,
      rootURL: `${winURL}/${companySlug}`,
      winApiURL: `${winURL}/api/v1`,
      rootApiURL: `${winURL}/api/v1/${companySlug}`,
      roundSetting: JSON.parse(displayPoint).round_setting,
      amountRoundSetting: JSON.parse(displayPoint).amount_round_setting,
      popupWindow: popupWindow,
      enterKeyCode: 13,
      selectKeyCodes: [9, 13],
    };
  },
  methods: {
    $inValidClass(errors) {
      return errors.length > 0 ? "custom-is-invalid" : "";
    },
    $setRestrictionForColums(columns) {
      _.forEach(columns, (col) => {
        switch (col.type) {
          case Settings.CELL_TYPE.INPUT:
            col.type = Settings.CELL_TYPE.RESTRICTION_INPUT;
            break;
          case Settings.CELL_TYPE.BUTTON:
            col.type = Settings.CELL_TYPE.RESTRICTION_BUTTON;
            break;
          case Settings.CELL_TYPE.CHECKBOX:
            col.type = Settings.CELL_TYPE.RESTRICTION_CHECKBOX;
            col.disabled = true;
            break;
          case Settings.CELL_TYPE.SELECT:
            col.type = Settings.CELL_TYPE.RESTRICTION_SELECT;
            break;
          case Settings.CELL_TYPE.SELECT_TAGGABLE:
            col.type = Settings.CELL_TYPE.RESTRICTION_SELECT_TAGGABLE;
            break;
          default:
        }
      });

      return columns;
    },
    $costSettingModalText(kind) {
      let textMessage, textYes, textNo;

      if (kind == 0) {
        textMessage = Settings.CONFIRM_CHANGE_COST_SETTING_MACHINE.MESSAGE;
        textYes = Settings.CONFIRM_CHANGE_COST_SETTING_MACHINE.YES;
        textNo = Settings.CONFIRM_CHANGE_COST_SETTING_MACHINE.NO;
      } else {
        textMessage = Settings.CONFIRM_CHANGE_COST_SETTING_LABOR.MESSAGE;
        textYes = Settings.CONFIRM_CHANGE_COST_SETTING_LABOR.YES;
        textNo = Settings.CONFIRM_CHANGE_COST_SETTING_LABOR.NO;
      }

      return {
        textMessage: textMessage,
        textYes: textYes,
        textNo: textNo,
      };
    },
    $costSettingLabels(costSettings) {
      const labels = {
        allowance1: costSettings.allowance1_multiplier.label,
        allowance2: costSettings.allowance2_multiplier.label,
        allowance3: costSettings.allowance3_multiplier.label,
        allowance4: costSettings.allowance4_multiplier.label,
        allowance5: costSettings.allowance5_multiplier.label,
      };

      return labels;
    },
    $currentColumns(colums, labels) {
      _.forEach(labels, (name, key) => {
        const objIndex = colums.findIndex((obj) => obj.code === key);
        if (objIndex >= 0) {
          colums[objIndex].name = name;
        }
      });

      return colums;
    },
    $costSettingSetCustomizeHeaderFields(fields, data) {
      for (let i = 0; i < fields.length; i++) {
        const fieldName = fields[i].fieldName;
        const label = data[fieldName].label;

        if (!_.isEmpty(label)) {
          fields[i].headerText = label;
        }
      }

      return fields;
    },
    $costSettingFieldsByLines(lines) {
      return _.filter(Settings.COST_SETTING_FIELDS, function (settingField) {
        return lines.includes(settingField.line);
      });
    },
    $costSettingFieldsByFieldNames(fieldNames) {
      return _.filter(Settings.COST_SETTING_FIELDS, function (settingField) {
        return fieldNames.includes(settingField.fieldName);
      });
    },
    $costSettingsFieldsModalLabor() {
      return this.$costSettingFieldsByFieldNames([
        "normal_wage_divisor",
        "overtime_wage_multiplier",
        "nighttime_wage_multiplier",
        "holiday_wage_multiplier",
        "allowance1_multiplier",
        "allowance2_multiplier",
        "allowance3_multiplier",
        "allowance4_multiplier",
        "allowance5_multiplier",
      ]);
    },
    $costSettingsFieldsModalMachine() {
      return this.$costSettingFieldsByFieldNames(["hourly_cost_divisor"]);
    },
    $existingCostSettingDataCompany(settingCompanyData) {
      for (const value of Settings.COST_SETTING_FIELDS) {
        if (!_.isEmpty(settingCompanyData[value.fieldName])) {
          return true;
        }
      }

      return false;
    },
    $openModal(component, properties = {}) {
      this.$eventBus.$emit("open-modal", {
        component,
        properties,
      });
    },
    $closeModal() {
      this.$eventBus.$emit("close-modal");
    },
    $randomString(length = 7) {
      const possibleChars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      let text = "";
      for (let i = 0; i < length; i++)
        text += possibleChars.charAt(
          Math.floor(Math.random() * possibleChars.length)
        );
      return text;
    },
    $reloadPage(errors = []) {
      if (errors.length === 0) {
        window.location.reload();
      }
    },
    $openBudgetPage(value) {
      this.$openNewWindow(
        this.$linkPathBudgetPage(value.project_id, value.budget_id),
        this.$windowNameWithBudget(value.budget_id)
      );
    },
    $linkPathBudgetPage(projectId, budgetId) {
      return `${this.$data.rootURL}/projects/${projectId}/budgets/${budgetId}`;
    },
    $windowNameWithBudget(budgetId) {
      return `ByBudget${budgetId}`;
    },
    $openNewWindow(linkPath, windowName, width = "1620", left = "", height= "768") {
      let subwindow = window.open(
        linkPath,
        windowName,
        "PopUp" +
          this.$randomString() +
          ",scrollbars=1,menubar=0,resizable=1,width=" +
          width +
          ",height=" +
          height +
          ",left=" +
          left
      );
      this.popupWindow.push(subwindow);
      window.onunload = () => {
        _.forEach(this.popupWindow, (popup) => {
          if (!_.startsWith(popup.name, "ByUnload")) {
            popup.close();
          }
        });
      };
    },
    $openNewOrderDetailTemplateWindow(linkPath, windowName, width = "1520") {
      let subwindow = window.open(
        linkPath,
        windowName,
        "PopUp" +
          this.$randomString() +
          ",scrollbars=1,menubar=0,resizable=1,width=" +
          width +
          ",height=940"
      );
      this.popupWindow.push(subwindow);
      window.onunload = () => {
        _.forEach(this.popupWindow, (popup) => {
          if (!_.startsWith(popup.name, "ByUnload")) {
            popup.close();
          }
        });
      };
    },

    $openCompanyPatternPage({ params }) {
      let path = `${this.$data.rootURL}/company_patterns`;

      params = _.reduce(
        params,
        (result, value, key) => {
          result.push(`${key}=${value}`);
          return result;
        },
        []
      );

      params = _.join(params, "&");
      if (!_.isEmpty(params)) path = `${path}?${params}`;

      this.$openNewWindow(path, params, "1570");
    },
    $openUserPatternPage({ projectId, params }) {
      let path = `${this.$data.rootURL}/user_patterns`;

      params = _.reduce(
        params,
        (result, value, key) => {
          result.push(`${key}=${value}`);
          return result;
        },
        []
      );

      params = _.join(params, "&");
      if (!_.isEmpty(params)) path = `${path}?${params}`;

      this.$openNewWindow(path, params, "1570");
    },
    $openProjectPatternPage({ projectId, params }) {
      let path = `${this.$data.rootURL}/projects/${projectId}/project_patterns`;

      params = _.reduce(
        params,
        (result, value, key) => {
          result.push(`${key}=${value}`);
          return result;
        },
        []
      );

      params = _.join(params, "&");
      if (!_.isEmpty(params)) path = `${path}?${params}`;

      this.$openNewWindow(path, params, "1570");
    },
    $createToday() {
      return this.$moment().format(Settings.DATE_FORMAT.JAPANESE);
    },
    $createToMonths() {
      return this.$moment().format(Settings.DATE_FORMAT.MONTH_YEAR_JP);
    },
    $isTabActive(tab) {
      return _.isEqual(this.currentTab, tab);
    },
    $subtract(midnuend, subtrahend) {
      return midnuend - subtrahend;
    },
    $multiply(factorA, factorB) {
      return factorA * factorB;
    },
    $errorsParse(element, errors) {
      let arr_errors = [];
      if (errors && errors[element]) {
        arr_errors.push(errors[element]);
      }
      return arr_errors;
    },
    $openCostDataPage(
      projectId,
      budgetId,
      dailyReportDate,
      groupId,
      costTypeId
    ) {
      let linkPath = `${this.$data.rootURL}/projects/${projectId}/project_unit_costs/data_import?budget_id=${budgetId}&group_id=${groupId}&cost_type_id=${costTypeId}&daily_report_date=${dailyReportDate}`;
      if(dailyReportDate){
        this.$openNewWindow(
          linkPath,
          `byBudget${budgetId}byDailyReport${dailyReportDate}`,
          "720",
          "1570"
        );
      } else {
        this.$openNewWindow(
          linkPath,
          `byBudget${budgetId}byDailyReport${dailyReportDate}`,
          "720",
        );
      }
    },
    $classNegativeNumber(data) {
      if (data < 0) return "text-danger";
    },
    $unitCurrency() {
      return Settings.UNIT.CURRENCY;
    },
    $toolTipQuantityFormat(value, precision = 3, separator = ",") {
      let val = parseFloat(value.toFixed(precision));
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    },
    $redirectTo(linkPath) {
      window.location.href = linkPath;
    },
    $englishToJapanDate(date) {
      return this.$moment(date, Settings.DATE_FORMAT.ENGLISH).format(
        Settings.DATE_FORMAT.JAPANESE
      );
    },
    $japanToEnglishDate(date) {
      return this.$moment(date, Settings.DATE_FORMAT.JAPANESE).format(
        Settings.DATE_FORMAT.ENGLISH
      );
    },
    $japanToEnglishWCurrentDate(date) {
      return this.$moment(date, Settings.CURRENT_DATE_FORMAT.JAPANESE).format(
        Settings.CURRENT_DATE_FORMAT.ENGLISH
      );
    },
    $yearFormat(date) {
      return `${this.$moment(date, Settings.DATE_FORMAT.ENGLISH).format(
        Settings.DATE_FORMAT.YEAR
      )}`;
    },
    $dayWeekFormat(date) {
      return this.$moment(date, Settings.DATE_FORMAT.ENGLISH).format(
        Settings.DATE_FORMAT.WEEK_DAY
      );
    },
    $monthAndDayFormat(date) {
      return `${this.$moment(date, Settings.DATE_FORMAT.ENGLISH).format(
        Settings.DATE_FORMAT.MONTH_DAY_EN
      )}`;
    },
    $getYesterday(date) {
      return this.$moment(date, Settings.DATE_FORMAT.ENGLISH)
        .subtract(1, "days")
        .endOf("day")
        .format(Settings.DATE_FORMAT.ENGLISH);
    },
    $getTomorrow(date) {
      return this.$moment(date, Settings.DATE_FORMAT.ENGLISH)
        .add(1, "days")
        .endOf("day")
        .format(Settings.DATE_FORMAT.ENGLISH);
    },
    $windowCloseAndReloadOpener() {
      window.close();
      window.opener.location.reload();
    },
    async $apiDelete(url) {
      const { data } = await axios.delete(url).then((response) => {
        return response;
      });
      this.$reloadPage(data.errors);
    },
    async $checkLogin() {
      setInterval(async () => {
        await axios
        .get(`${this.$data.rootApiURL}/account_capabilities/login_status.json`)
        .catch((e) => {
          this.$notify({
            type: "success",
            text: "ログインが重複しています。再度、ログインしてください。",
            duration: 3000,
          });
          setTimeout(() => {
            this.$reloadPage();
          }, 3000);
        });
      }, 10000);
    },
    $percentCalculation(dividend, divisor) {
      return divisor > 0 ? (dividend / divisor) * 100 : 0;
    },
    $openDialogPrint() {
      window.print();
    },
    $keyCodeIsOnSelect(keyCode) {
      return this.selectKeyCodes.includes(keyCode);
    },
    $showDropDownByRefId(refId) {
      this.$nextTick(() => {
        this.$refs[refId].$el.querySelector("input").focus();
      });
    },
    $focusNumericInput(refId) {
      this.$nextTick(() => {
        this.$refs[refId].$el.focus();
      });
    },
    $focusButton(refId) {
      this.$nextTick(() => {
        this.$refs[refId].focus();
      });
    },
    // Handle next focus
    $handleFocusWithTagWrapper(ref, event = null) {
      if (event != null && !this.$keyCodeIsOnSelect(event.keyCode)) return;

      this.$refs[ref].$el.querySelector("input").focus();
    },
    $handleFocusWithOutTagWrapper(ref, event) {
      if (event.keyCode !== 13) return;

      this.$refs[ref].$el.focus();
    },
    $handleFocusWithButton(ref, event) {
      event.preventDefault();
      if (event.type !== "change" && event.keyCode !== 13) return;
      this.$refs[ref].focus();
    },
    $handleSelectParams(column, object) {
      if (_.isObject(object)) {
        if (column === "unit_cost_type" || column === "cost_type") {
          return { name: object.label, id: object.value };
        } else {
          return object.label;
        }
      } else {
        return object;
      }
    },
    $formatThousand(str) {
      return String(str).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    $convertFullWidthToHalfWidth(str) {
      return String(str)
        .replace(/[０-９]/g, function (s) {
          return String.fromCharCode(s.charCodeAt(0) - 65248);
        })
        .replace("。", ".")
        .replace("．", ".")
        .replace("−", "-")
        .replace("ー", "-");
    },
    $sumHoursColumn(drResource) {
      return _.sum([
        drResource.normal_wage_hours,
        drResource.overtime_wage_hours,
        drResource.nighttime_wage_hours,
        drResource.holiday_wage_hours,
        drResource.allowance1_hours,
        drResource.allowance2_hours,
        drResource.allowance3_hours,
        drResource.allowance4_hours,
        drResource.allowance5_hours,
      ]);
    },
    $refreshOpener() {
      window.opener.location.reload();
    },
    $maxNumberQuantityAndUnitCostColumn() {
      return 999999999999.999;
    },
    $minNumberQuantityAndUnitCostColumn() {
      return -999999999999.999;
    },
    $isLabor(drResource) {
      return drResource.unit_cost_type.label === Settings.DR_RESOURCE.LABOR;
    },
    $isMachinery(drResource) {
      return _.includes(machineryCostNames(), drResource.unit_cost_type.label);
    },
    $inputQuantityTime(drResource) {
      return drResource.mode === Settings.DR_RESOURCE.MODE_TIME;
    },
    $inputQuantityNormal(drResource) {
      return drResource.mode === Settings.DR_RESOURCE.MODE_NORMAL;
    },
    $checkNumber(value) {
      if (value === "") return 0;

      let result = this.$convertFullWidthToHalfWidth(value);
      let regex = new RegExp(/^-?\d*(\.\d+)?$/);
      let isNumber = regex.test(result);

      return isNumber ? +result : 0;
    },
    $isTeamSelected(plan, selected) {
      return (
        !_.isEmpty(plan) &&
        (selected === Settings.PLAN.TEAM_PLAN_MONTHLY ||
          selected === Settings.PLAN.TEAM_PLAN_YEARLY ||
          selected === Settings.PLAN.TEAM_PLAN_MONTHLY202204 ||
          selected === Settings.PLAN.TEAM_PLAN_YEARLY202204)
      );
    },
    $isProcessSheetSelected(plan, selected) {
      return (
        !_.isEmpty(plan) &&
        (selected === Settings.PLAN.PROCESS_SHEET_PLAN_MONTHLY ||
          selected === Settings.PLAN.PROCESS_SHEET_PLAN_YEARLY)
      );
    },
    $isFreePlan(plan) {
      return plan.slug === Settings.PLAN.FREE_PLAN;
    },
    $isTeamPlan(plan) {
      return (
        plan.slug === Settings.PLAN.TEAM_PLAN_MONTHLY ||
        plan.slug === Settings.PLAN.TEAM_PLAN_YEARLY ||
        plan.slug === Settings.PLAN.TEAM_PLAN_MONTHLY202204 ||
        plan.slug === Settings.PLAN.TEAM_PLAN_YEARLY202204
      );
    },
    $isProcessSheetPlan(plan) {
      return (
        plan.slug === Settings.PLAN.PROCESS_SHEET_PLAN_MONTHLY ||
        plan.slug === Settings.PLAN.PROCESS_SHEET_PLAN_YEARLY
      );
    },
    $isPersonalPlan(plan) {
      return plan.slug === Settings.PLAN.PERSONAL_PLAN_MONTHLY;
    },
    $handleShowAndHideColumn(
      unitCostTypeId,
      tabList,
      columns,
      costSettingLabels
    ) {
      let tab = _.find(tabList, { value: unitCostTypeId });
      let showColumnsName = [];

      if (tab.label == conditionType.all) {
        showColumnsName = [
          ...columnsDefault,
          ...columnsMachinery,
          ...[
            ..._.dropRight(columnsLaborSalary, 5),
            ...Object.values(costSettingLabels),
          ],
        ];
      } else if (tab.label == conditionType.labor) {
        showColumnsName = [
          ...columnsDefault,
          ...[
            ..._.dropRight(columnsLaborSalary, 5),
            ...Object.values(costSettingLabels),
          ],
        ];
      } else if (_.includes(machineryCostNames(), tab.label)) {
        showColumnsName = [...columnsDefault, ...columnsMachinery];
      } else {
        showColumnsName = [...columnsDefault];
      }

      _.forEach(columns, (column) => {
        column.is_show = _.includes(showColumnsName, column.name);
      });
    },
    $cookieExist(key) {
      return this.$cookies.isKey(key);
    },
    $setCookie(key, value, path = window.location.pathname, expire = null) {
      this.$cookies.set(key, value, expire, path);
    },
    $getCookie(key) {
      return this.$cookies.get(key);
    },
    $setScrollTopInCookie(key, path = window.location.pathname) {
      this.$setCookie(key, document.documentElement.scrollTop, path);
    },
    $getScrollTopInCookie(key) {
      return this.$getCookie(key) || 0;
    },
    $openOrderDetailTemplate(params = {}) {
      let path = `${this.$data.rootURL}/order_detail_templates`;
      if (!_.isEmpty(params)) path = `${path}?${$.param(params)}`;

      this.$openNewOrderDetailTemplateWindow(
        path,
        `orderDetailTemplatesProject${params}`
      );
    },
    $formatProjectDateAt(date_at) {
      if (!date_at) return "";
      return this.$moment(date_at).format(Settings.DATE_FORMAT.JAPANESE);
    },
    $onSort(currentSort, currentSortDir, list) {
      const data = list.sort((a, b) => {
        if (!a[currentSort] || !b[currentSort]) return;

        let aLabel = "";
        let bLabel = "";
        let modifier = 1;
        if (currentSortDir === "desc") {
          modifier = -1;
        }
        if (!a[currentSort].label || !b[currentSort].label) {
          aLabel = a.unit_cost_category.label
            ? `${a.unit_cost_category.label} | ${a[currentSort]}`
            : `${a[currentSort]}`;
          bLabel = b.unit_cost_category.label
            ? `${b.unit_cost_category.label} | ${b[currentSort]}`
            : `${b[currentSort]}`;
        }
        if (a[currentSort].label < b[currentSort].label || aLabel < bLabel) {
          return -1 * modifier;
        }
        if (a[currentSort].label > b[currentSort].label || aLabel > bLabel) {
          return 1 * modifier;
        }
        return 0;
      });
      return data;
    },
  },
  filters: {
    $formatQuantity(value) {
      return value
        .toFixed(3)
        .replace(Settings.REGEX_FORMAT_NUMBER, ",")
        .replace(Settings.REGEX_FORMAT_FLOAT_WITHOUT_0, "$1");
    },
    $formatCostTypeQuantity(value) {
      return _.round(value, 4)
        .toFixed(4)
        .replace(Settings.REGEX_FORMAT_FLOAT_WITHOUT_0, "$1");
    },
    $formatPercent(value) {
      return value
        .toFixed(2)
        .replace(Settings.REGEX_FORMAT_NUMBER, ",")
        .replace(Settings.REGEX_FORMAT_FLOAT_WITHOUT_0, "$1");
    },
    $formatUnitCost(
      value,
      roundSetting = { precision: 3, round_type: "depend" },
      isRegexFormat = true
    ) {
      let number;
      switch (roundSetting.round_type) {
        case "down":
          number = _.floor(value, roundSetting.precision);
          break;
        case "up":
          number = _.ceil(value, roundSetting.precision);
          break;
        case "depend":
          number = _.round(value, roundSetting.precision);
      }
      return isRegexFormat
        ? number
            .toFixed(roundSetting.precision)
            .replace(Settings.REGEX_FORMAT_NUMBER, ",")
            .replace(Settings.REGEX_FORMAT_FLOAT_WITHOUT_0, "$1")
        : number;
    },
    $formatUnitCostWithThreeDot(value) {
      let numParts = value.toString().split(".");
      numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (numParts[1] && numParts[1].length > 2) {
        numParts[1] = `${numParts[1].split("")[0]}${
          numParts[1].split("")[1]
        }...`;
      }
      return numParts.join(".");
    },
    $formatAmount(value) {
      return _.floor(value, 0)
        .toFixed(0)
        .replace(Settings.REGEX_FORMAT_NUMBER, ",")
        .replace(Settings.REGEX_FORMAT_FLOAT_WITHOUT_0, "$1");
    },
    $formatAmountWithSetting(
      value,
      amountRoundSetting = { precision: 7, round_type: "amount_down" }
    ) {
      let number;
      switch (amountRoundSetting.round_type) {
        case "amount_down":
          number = _.floor(value, amountRoundSetting.precision);
          break;
        case "amount_up":
          number = _.ceil(value, amountRoundSetting.precision);
          break;
        case "amount_depend":
          number = _.round(value, amountRoundSetting.precision);
      }
      return new Intl.NumberFormat("ja-JP", {
        maximumFractionDigits: amountRoundSetting.precision,
      }).format(number);
    },
    $formatUnitCostWithTo3rdDecimal(value) {
      let number = _.floor(value, 3);
      return number
        .toFixed(3)
        .replace(Settings.REGEX_FORMAT_NUMBER, ",")
        .replace(Settings.REGEX_FORMAT_FLOAT_WITHOUT_0, "$1");
    },
    $formatUnitCostWithTo6rdDecimal(value) {
      let number = _.round(value, 6);
      return number
        .toFixed(6)
        .replace(Settings.REGEX_FORMAT_FLOAT_WITHOUT_0, "$1");
    },
    $amountResources(quantity, unit_cost) {
      let x = new BigNumber(quantity);
      let y = new BigNumber(unit_cost);

      return x.multipliedBy(y).toNumber();
    },
    $ceil(value) {
      return _.ceil(value);
    },
    $formatSumHours(value) {
      return `${value
        .toFixed(2)
        .replace(Settings.REGEX_FORMAT_NUMBER, ",")
        .replace(Settings.REGEX_FORMAT_FLOAT_WITHOUT_0, "$1")} 時間`;
    },
  },
};
